import React, { useState, useContext, useCallback } from 'react';
import { nanoid } from 'nanoid';
import { childrenType } from '../types';
import { Notifications } from '../components/core/notifications';

export const NotificationContext = React.createContext(null);

export const useNotification = () => {
  const notificationHelpers = useContext(NotificationContext);

  return notificationHelpers;
};

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  const addNotification = useCallback(
    (variant, content) => {
      const id = nanoid();
      setNotifications((prevNotifications) => [
        ...prevNotifications,
        {
          id,
          variant,
          content,
        },
      ]);
      return id;
    },
    [setNotifications],
  );

  const removeNotification = useCallback(
    (notificationId) => {
      setNotifications((prevNotifications) => prevNotifications.filter(
        (notification) => notification.id !== notificationId,
      ));
    },
    [setNotifications],
  );

  return (
    <NotificationContext.Provider value={{ addNotification, removeNotification }}>
      <Notifications notifications={notifications} onRemove={removeNotification} />
      {children}
    </NotificationContext.Provider>
  );
};

NotificationProvider.propTypes = {
  children: childrenType.isRequired,
};
