import { resolve } from '../../../helpers/urls';

export const getLinkProps = (reference) => {
  const isInternalLink = (typeof reference.element?.slug === 'string');

  if (!isInternalLink) {
    return {
      to: reference.url,
      target: '_blank',
      rel: 'noopener',
    };
  }

  const { __typename: typename } = reference.element;
  const hash = reference?.url || '';

  switch (typename) {
    case 'CraftGQL_pages_pages_Entry':
      return {
        to: `${resolve('pages:detail', {
          pageSlug: reference.element?.slug,
          departmentSlug: reference.element?.departments[0]?.slug,
        })}${hash}`,
        target: reference.target,
      };

    case 'CraftGQL_news_news_Entry':
      return {
        to: `${resolve('news:detail', {
          pageSlug: reference?.slug,
          // departmentSlug: reference.element?.departments[0]?.slug,
        })}${hash}`,
        target: reference.target,
      };

    case 'CraftGQL_departments_department_Entry':
      return {
        to: `${resolve('departments:detail', {
          departmentSlug: reference.element.slug,
        })}${hash}`,
        target: reference.target,
      };

    case 'CraftGQL_products_product_Entry':
      return {
        to: `${resolve('products:detail', {
          departmentSlug: reference.element.departments[0].slug,
          productSlug: reference.element.slug,
          variantSlug: reference.element.variant[0].slug,
        })}${hash}`,
        target: reference.target,
      };

    default:
      return {
        href: `/#unsupported-${typename}`,
      };
  }
};
