import { canUseDOM } from 'exenv';
import React, {
  createContext,
  useCallback, useContext, useMemo, useReducer,
} from 'react';

import { childrenType } from '../types';

const Context = createContext();

const reducer = (state, action) => {
  const {
    type, key, value, parse,
  } = action;

  switch (type) {
    case 'set':
      window.localStorage.setItem(key, parse ? JSON.stringify(value) : value);
      return { ...state, [key]: value };
    case 'unset':
      window.localStorage.removeItem(key);
      return { ...state, [key]: value };
    default:
      return state;
  }
};

export const useLocalStorage = (key, options) => {
  const { parse, fallback } = {
    // Default values:
    parse: false,
    fallback: null,
    // Passed options:
    ...options,
  };

  if (!canUseDOM) {
    return [
      fallback || null, // replacement value for SSR
      () => undefined, // replacement for 'setStorage'
      () => undefined, // replacement for 'unsetStorage'
    ];
  }

  const initial = useMemo(() => {
    const val = window.localStorage.getItem(key);
    return (parse ? JSON.parse(val) : val) || fallback;
  }, []);

  const { state, dispatch } = useContext(Context);
  const current = state[key] || initial || fallback;

  const setStorage = useCallback(
    (value) => dispatch({
      type: 'set', key, value, parse,
    }),
    [key, parse, dispatch],
  );

  const unsetStorage = useCallback(
    () => dispatch({
      type: 'unset', key, value: fallback || null,
    }),
    [key, fallback, dispatch],
  );

  return [current, setStorage, unsetStorage];
};

export const LocalStorageProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {});
  const { Provider } = Context;

  return (<Provider value={{ state, dispatch }}>{children}</Provider>);
};

LocalStorageProvider.propTypes = {
  children: childrenType.isRequired,
};
