import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Cookieconsent } from '../components/core/cookieconsent';

import { useLocalStorage } from './useLocalStorage';
import { useModal } from './useModal';

const PRIVACY_KEY = '_privacy-consents';
const PRIVACY_GROUPS = [
  {
    key: 'tracking',
    title: (t) => t('Tracking'),
    description: (t) => t('We use tracking tools.'),
  },
  {
    key: 'contents',
    title: (t) => t('Contents'),
    description: (t) => t('We use 3rd party tools to display contents.'),
  },
];

export const usePrivacy = () => {
  const [consents, save] = useLocalStorage(PRIVACY_KEY, { fallback: {}, parse: true });
  const { t } = useTranslation();

  const groups = useMemo(() => PRIVACY_GROUPS.map(({ key, title, description }) => ({
    key,
    title: title(t),
    description: description(t),
    allowed: consents[key],
  })), [consents, t]);

  const update = useCallback((newConsents) => {
    if (newConsents.length !== PRIVACY_GROUPS.length) {
      throw new Error('Incorrect amount of given consents.');
    }

    const value = PRIVACY_GROUPS.reduce(
      (acc, group, index) => ({ ...acc, [group.key]: newConsents[index] }), {},
    );

    save(value);
  }, [save]);

  return { groups, update };
};

export const usePrivacyConsent = (key) => {
  const [consents] = useLocalStorage(PRIVACY_KEY, { fallback: {}, parse: true });
  return consents[key] || false;
};

export const usePrivacyModal = () => {
  const { t } = useTranslation();
  const { groups, update: updateCallback } = usePrivacy();
  const options = useMemo(() => ({
    title: t('Cookie Settings'),
    isPrompt: true,
    componentProps: { groups, updateCallback },
  }), [t, groups, updateCallback]);
  const { showModal } = useModal(Cookieconsent, options);

  return { showModal };
};
