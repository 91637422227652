import { useCallback } from 'react';

import { useLocalStorage } from './useLocalStorage';

export const MAX_UNITS = 99999;

const STORAGE_KEY = '_medskinCart-v1';

const createEntry = (productId, variantId, quantity, sample, moq, moqIncrement) => ({
  pId: productId, vId: variantId, quantity, sample, moq: moq, moqIncrement
});

const compareEntries = (a, b) => (a.pId === b.pId && a.vId === b.vId && a.sample === b.sample);

const canAdd = (cart, productId, variantId, sample, moq, moqIncrement) => {
  // Search for a variant that uses a the opposite sample option. If the opposite
  // was found, we can not add this variant.
  let search = createEntry(productId, variantId, 0, !sample, moq, moqIncrement);
  const product = cart.find((entry) => compareEntries(entry, search));

  if (product?.quantity >= MAX_UNITS) {
    return false;
  }

  if (!sample) {
    return !product;
  }

  // For samples it is required that only one sample is in the cart.
  search = createEntry(productId, variantId, 0, true, moq);
  return !product && !cart.find((entry) => compareEntries(entry, search));
};

const clearCart = (setCart) => setCart([]);

const removeFromCart = (cart, setCart, productId, variantId, sample, moq, moqIncrement) => {
  const search = createEntry(productId, variantId, 0, sample, moq);
  const removed = cart.filter((entry) => !compareEntries(entry, search));
  setCart(removed);
};

const addToCart = (cart, setCart, productId, variantId, quantity, sample, moq, moqIncrement) => {
  if (quantity < 1) {
    throw new Error('Quantity must be more than 1');
  }

  if (quantity < moq) {
    throw new Error('Quantity must be at least ' + moq + ' for this product');
  }

  if (!canAdd(cart, productId, variantId, sample)) {
    throw new Error('Can not add to cart. There is already an entry that uses an other sample option.');
  }

  const toAdd = createEntry(productId, variantId, 0, sample, moq);
  let product = cart.find((entry) => compareEntries(entry, toAdd));
  if (!product) {
    product = toAdd;
    cart.push(product);
  }

  product.quantity = sample ? 1 : (product.quantity + quantity);

  if (product.quantity > MAX_UNITS) {
    throw new Error('Can not add to cart. Maximum for this product reached.');
  }

  setCart(cart);
};

const updateCart = (cart, setCart, productId, variantId, quantity, sample, moq, moqIncrement) => {
  if (quantity < 0) {
    throw new Error('Quantity must be more than 0');
  }

  if (quantity === 0) {
    removeFromCart(cart, setCart, productId, variantId, sample);
    return;
  }

  if (!canAdd(cart, productId, variantId, sample)) {
    throw new Error('Can not add to cart. There is already an entry that uses an other sample option.');
  }

  const search = createEntry(productId, variantId, 0, sample, moq, moqIncrement);

  const product = cart.find((entry) => compareEntries(entry, search));
  if (!product) {
    throw new Error('Product not found in cart');
  }

  product.quantity = sample ? 1 : quantity;

  if (product.quantity < product.moq) {
    throw new Error('This product has a minimum order quantity of ' + product.moq + '.');
  }

  if (product.quantity > MAX_UNITS) {
    throw new Error('Can not update cart. Maximum for this product reached.');
  }

  setCart(cart);
};

export const useCart = () => {
  const [cart, setCart] = useLocalStorage(STORAGE_KEY, { fallback: [], parse: true });
  const isEmpty = cart.length === 0;

  return {
    cart,
    isEmpty,

    canAdd: useCallback(
      (productId, variantId, sample) => canAdd(
        cart, productId, variantId, sample,
      ),
      [cart],
    ),
    addToCart: useCallback(
      (productId, variantId, quantity, sample = false, moq, moqIncrement) => addToCart(
        cart, setCart, productId, variantId, quantity, sample, moq, moqIncrement
      ),
      [cart, setCart],
    ),
    updateCart: useCallback(
      (productId, variantId, quantity, sample = false, moq, moqIncrement) => updateCart(
        cart, setCart, productId, variantId, quantity, sample, moq, moqIncrement
      ),
      [cart, setCart],
    ),
    removeFromCart: useCallback(
      (productId, variantId, sample = false, moq, moqIncrement) => removeFromCart(
        cart, setCart, productId, variantId, sample, moq, moqIncrement
      ),
      [cart, setCart],
    ),
    clearCart: useCallback(
      () => clearCart(setCart),
      [setCart],
    ),
  };
};
