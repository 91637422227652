import loadable from '@loadable/component';
import React, {
  createContext, useCallback, useContext, useEffect, useRef, useState,
} from 'react';
import { childrenType } from '../types';

const Modal = loadable(() => import('../components/common/modal'));

export const ModalContext = createContext();

export const useModal = (Component, {
  title, isPrompt, componentProps,
}) => {
  const { setProps } = useContext(ModalContext);

  const hideModal = useCallback(
    () => setProps(null),
    [setProps],
  );

  const showModal = useCallback(
    () => {
      const mergedComponentProps = (typeof componentProps === 'function')
        ? () => ({ ...componentProps(), closeCallback: hideModal })
        : { ...componentProps || {}, closeCallback: hideModal };

      setProps({
        Component,
        title,
        isPrompt,
        componentProps: mergedComponentProps,
      });
    },
    [Component, componentProps, hideModal, isPrompt, setProps, title],
  );

  return { showModal, hideModal };
};

export const ModalProvider = ({ children }) => {
  const [props, setProps] = useState(null);
  const closeCallback = useCallback(() => setProps(null), [setProps]);
  const ref = useRef(null);

  const isVisible = props !== null;

  useEffect(() => {
    if (!ref?.current) {
      return;
    }

    const { current } = ref;
    current.inert = isVisible;
  }, [isVisible]);

  return (
    <ModalContext.Provider value={{ setProps }}>
      <div id="modal-provider" ref={ref}>{children}</div>
      {isVisible
        // eslint-disable-next-line react/jsx-props-no-spreading
        ? (<Modal {...props} closeCallback={closeCallback} />)
        : null}
    </ModalContext.Provider>
  );
};

ModalProvider.propTypes = {
  children: childrenType.isRequired,
};
