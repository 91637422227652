import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import BEMHelper from 'react-bem-helper';
import './button.scss';

const bem = new BEMHelper({
  name: 'button',
});

export const Button = ({
  children,
  to,
  href,
  type,
  disabled,
  title,
  target,
  variant,
  className,
  ...rest
}) => {
  let Element;
  let requiredProps;

  if (to) {
    Element = Link;
    requiredProps = {
      to,
      title,
      target,
      rel: target === '_blank' ? 'noopener' : undefined,
    };
  } else if (href) {
    Element = 'a';
    requiredProps = {
      href,
      title,
      target,
      rel: target === '_blank' ? 'noopener' : undefined,
    };
  } else {
    Element = 'button';
    requiredProps = {
      title,
      type: type || 'button',
      disabled,
    };
  }

  return (
    <Element
      {...bem(null, variant, className)}
      {...requiredProps}
      {...rest}
    >
      {children}
    </Element>
  );
};

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  to: PropTypes.string,
  href: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  title: PropTypes.string.isRequired,
  target: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'secondary']),
  className: PropTypes.string,
};

Button.defaultProps = {
  to: null,
  href: null,
  target: undefined,
  type: 'button',
  disabled: false,
  variant: 'primary',
  className: null,
};
