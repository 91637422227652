import 'what-input';
import 'wicg-inert';
import React from 'react';
import PropTypes from 'prop-types';

import { Footer } from '../../components/core/footer';
import { Header } from '../../components/core/header';
import { Contents } from '../../components/core/contents';
import { Navigation } from '../../components/core/navigation';
import { Privacycontrol } from '../../components/core/privacycontrol';
import { useGoogleTagManager } from './hooks/useGoogleTagManager';
import './Layout.scss';

// eslint-disable-next-line import/no-dynamic-require
require(`styles/static/themes/${process.env.GATSBY_SITE_THEME}.css`);

const Layout = ({ children }) => {
  useGoogleTagManager(process.env.GATSBY_GOOGLETAGMANAGER_ID);

  return (
    <>
      <Header>
        <Navigation contentsId="contents" />
      </Header>
      <Contents id="contents">
        <main className="main">{children}</main>
        <Footer />
        <Privacycontrol />
      </Contents>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
