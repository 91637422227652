import { WebAuth } from 'auth0-js';

import { resolve as resolveUrl } from '../../helpers/urls';

/* Using the deploy prime url here, to work across all netlify deploys:
 * https://docs.netlify.com/configure-builds/environment-variables/#deploy-urls-and-metadata */
const AUTH_ENABLED = process.env.GATSBY_AUTH0_ENABLED;
const SITE_ORIGIN = process.env.GATSBY_SITE_ORIGIN;
const DOMAIN = process.env.GATSBY_AUTH0_DOMAIN;
const TENANT = process.env.GATSBY_AUTH0_TENANT;
const CLIENT_ID = process.env.GATSBY_AUTH0_CLIENT_ID;
const DEPLOY_CONTEXT = process.env.CONTEXT;
const DEPLOY_ORIGIN = process.env.DEPLOY_PRIME_URL;

const ORIGIN = DEPLOY_CONTEXT === 'deploy-preview' ? DEPLOY_ORIGIN : SITE_ORIGIN;
const CALLBACK_URL = `${ORIGIN}${resolveUrl('auth:callback')}`;
const LOGOUT_URL = `${ORIGIN}${resolveUrl('auth:logout')}`;

const CONNECTION_REALM = 'Username-Password-Authentication';
const RESPONSE_TYPE = 'token id_token';
const SCOPE = 'openid profile email';

const CLIENT_OPTIONS = {
  domain: DOMAIN,
  clientID: CLIENT_ID,
  redirectUri: CALLBACK_URL,
  responseType: RESPONSE_TYPE,
  scope: SCOPE,
  overrides: {
    __tenant: TENANT,
    __token_issuer: `https://${DOMAIN}/`,
  },
};

export const isEnabled = AUTH_ENABLED === 'true';

export const createClient = (options = null) => new WebAuth({ ...CLIENT_OPTIONS, ...options });

export const parseHash = (client) => new Promise((resolve, reject) => {
  client.parseHash((error, response) => {
    if (error) { reject(error); }
    resolve(response);
  });
});

export const userInfo = (client, token) => new Promise((resolve, reject) => {
  client.client.userInfo(token, (error, response) => {
    if (error) { reject(error); }
    resolve(response);
  });
});

export const checkSession = (client) => new Promise((resolve, reject) => {
  client.checkSession({}, (error, response) => {
    if (error) { reject(error); }
    resolve(response);
  });
});

export const validateUser = async (client) => {
  let user = null;
  try {
    user = await parseHash(client);
    user = user || await checkSession(client);
  } catch (error) {
    const { error: type, errorDescription: message } = error || {};

    switch (type) {
      case 'server_error':
        throw new Error(message || 'Authentication error');
      default:
        break;
    }

    user = null;
  }

  if (!user || !user.accessToken) {
    return null;
  }

  try {
    const info = await userInfo(client, user.accessToken);
    user = { ...user, info };
  } catch (error) {
    user = null;
  }

  return user;
};

export const login = (client, { email, password }) => new Promise((resolve, reject) => {
  client.login({ realm: CONNECTION_REALM, email, password }, (error) => {
    if (error) { reject(error); }
    resolve();
  });
});

export const logout = (client) => new Promise((resolve) => {
  client.logout({ returnTo: LOGOUT_URL });
  resolve();
});

export const reset = (client, { email }) => new Promise((resolve, reject) => {
  client.changePassword({ connection: CONNECTION_REALM, email }, (error) => {
    if (error) { reject(error); }
    resolve();
  });
});
