import { canUseDOM } from 'exenv';
import React from 'react';

import { useAuth } from '../../../../hooks/useAuth';
import { childrenType } from '../../../../types/children';

export const BehindLogin = ({ children }) => {
  const { isLoading, isAuthenticated } = useAuth();
  if (!canUseDOM || isLoading || !isAuthenticated) {
    return null;
  }

  return (<>{children}</>);
};

BehindLogin.propTypes = {
  children: childrenType.isRequired,
};

export const WithoutLogin = ({ children }) => {
  const { isLoading, isAuthenticated } = useAuth();
  if (canUseDOM && (isLoading || isAuthenticated)) {
    return null;
  }

  return (<>{children}</>);
};

WithoutLogin.propTypes = {
  children: childrenType.isRequired,
};
