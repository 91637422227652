import React from 'react';
import BEMHelper from 'react-bem-helper';
import { useTranslation } from 'react-i18next';
import { childrenType } from '../../../types';
import './header.scss';

const bem = BEMHelper({
  name: 'header',
});

export const Header = ({ children }) => {
  const { t } = useTranslation();

  return (
    <header {...bem()} role="banner">
      <a
        {...bem('skip')}
        href="#main"
        title={t('Click here to jump to main content')}
      >
        {t('Skip to main')}
      </a>
      <div {...bem('contents')}>{children}</div>
    </header>
  );
};

Header.propTypes = {
  children: childrenType.isRequired,
};
