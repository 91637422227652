import { graphql } from 'gatsby';
import { useCallback, useMemo } from 'react';

import { resolve } from '../helpers/urls';
import { useCart } from './useCart';
import { useLocalStorage } from './useLocalStorage';

const STORAGE_KEY = '_medskinCoupons-v1';
const STORAGE_KEY_CONTACT = '_medskinOrderContact-v1';
const STORAGE_KEY_COMMENT = '_medskinOrderComment-v1';

const SHOP_ENABLED = process.env.GATSBY_SHOP_ENABLED;
const DISCOUNT_ENABLED = process.env.GATSBY_SHOP_DISCOUNT_ENABLED;

export const CURRENCY = '€';

const isEnabled = SHOP_ENABLED === 'false';
const isDiscountEnabled = isEnabled && DISCOUNT_ENABLED === 'true';

export const fragments = graphql`
  fragment ShopHookFragment on CraftGQL {
    products: entries(section: "products") {
      ...on CraftGQL_products_product_Entry {
        uid
        slug
        title
        productArticleNumber
        departments: productDepartment {
          ...on CraftGQL_departments_department_Entry {
            slug
          }
        }
        variants: productVariants {
          ...on CraftGQL_variants_variants_Entry {
            uid
            slug
            label: variantLabel
            price: variantPrice
            sample: variantSample
            moq: variantMinimumOrderQuantity
            moqIncrement: variantQuantityIncrement
          }
        }
      }
    }

    codes: entries(section: "shopCouponCodes") {
      ...on CraftGQL_shopCouponCodes_shopCouponCodes_Entry {
        uid
        code: shopCouponCode
        value: shopCouponValue
      }
    }
  }
`;

const asKey = (pId, vId) => `${pId}__${vId}`;

const createProductDict = (products) => {
  const dict = {};

  products.forEach(({
    uid, slug, title, productArticleNumber, variants, departments: [department],
  }) => {
    variants.forEach((variant) => {
      const key = asKey(uid, variant.uid);
      dict[key] = {
        key,
        title,
        articleNo: productArticleNumber,
        label: variant.label,
        price: variant.price,
        sample: variant.sample,
        moq: variant.moq,
        moqIncrement: variant.moqIncrement,
        url: resolve('products:detail', {
          productSlug: slug,
          departmentSlug: department.slug,
          variantSlug: variant.slug,
        }),
        standalone: variants.length === 1,
      };
    });
  });
  return dict;
};

const lookupDict = (dict, pId, vId) => {
  const key = asKey(pId, vId);
  return dict[key];
};

const createEntry = (couponId) => ({ cId: couponId });

const compareEntries = (a, b) => (a.cId === b.cId);

const hasCoupon = (coupons, couponId) => {
  const search = createEntry(couponId);
  return !!coupons.find((entry) => compareEntries(entry, search));
};

const clearCoupons = (setCoupons) => setCoupons([]);

const addCoupon = (coupons, setCoupons, couponId) => {
  if (hasCoupon(coupons, couponId)) {
    throw new Error('Coupon code already applied.');
  }

  const coupon = createEntry(couponId);
  coupons.push(coupon);
  setCoupons(coupons);
};

const removeCoupon = (coupons, setCoupons, couponId) => {
  const search = createEntry(couponId);
  const removed = coupons.filter((entry) => !compareEntries(entry, search));
  setCoupons(removed);
};

const getSubTotal = (cart, dict) => cart.reduce(
  (acc, entry) => {
    const product = lookupDict(dict, entry.pId, entry.vId);
    if (!product) {
      // Product not available: Maybe longer in cart and currently not
      // published by CMS...
      return acc;
    }

    const multiplier = entry.sample ? 0 : 1;
    return acc + (product.price * entry.quantity * multiplier);
  },
  0,
);

const getTotal = (subtotal, coupons, codes) => coupons.reduce(
  (acc, { cId }) => {
    const coupon = codes.find(({ uid }) => uid === cId);
    if (!coupon) {
      return acc;
    }

    // Calculate discount factor in between 0 and 1.
    // Ensure factor is not less 0 (0 == 100% discount).
    const factor = Math.max((100 - coupon.value) / 100, 0);
    const total = acc * factor;
    return Math.round((total + Number.EPSILON) * 100) / 100;
  },
  subtotal,
);

export const useShop = (products = [], codes = []) => {
  const { cart } = useCart();
  const [coupons, setCoupons] = useLocalStorage(STORAGE_KEY, { fallback: [], parse: true });
  const dict = useMemo(() => createProductDict(products), [products]);
  const subtotal = getSubTotal(cart, dict);
  const total = getTotal(subtotal, coupons, codes);
  const hasDiscount = subtotal !== total;
  const [contactperson, setContactperson] = useLocalStorage(STORAGE_KEY_CONTACT, { fallback: [], parse: true });
  const             [comment, setComment] = useLocalStorage(STORAGE_KEY_COMMENT, { fallback: [], parse: true });

  return {
    isEnabled,
    isDiscountEnabled,
    coupons,
    subtotal,
    total,
    hasDiscount,
    contactperson,
    comment,

    lookup: useCallback(
      (productId, variantId) => lookupDict(
        dict, productId, variantId,
      ),
      [dict],
    ),
    addCoupon: useCallback(
      (code, value) => addCoupon(coupons, setCoupons, code, value),
      [coupons, setCoupons],
    ),
    removeCoupon: useCallback(
      (code) => removeCoupon(coupons, setCoupons, code),
      [coupons, setCoupons],
    ),
    clearCoupons: useCallback(
      () => clearCoupons(setCoupons),
      [setCoupons],
    ),
    setContactperson: useCallback(
      (person) => setContactperson(person),
      [contactperson, setContactperson]
    ),
    setComment: useCallback(
      (comment) => setComment(comment),
      [comment, setComment]
    )
  };
};
