exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-auth-callback-jsx": () => import("./../../../src/templates/Auth/Callback.jsx" /* webpackChunkName: "component---src-templates-auth-callback-jsx" */),
  "component---src-templates-auth-logout-jsx": () => import("./../../../src/templates/Auth/Logout.jsx" /* webpackChunkName: "component---src-templates-auth-logout-jsx" */),
  "component---src-templates-department-department-jsx": () => import("./../../../src/templates/Department/Department.jsx" /* webpackChunkName: "component---src-templates-department-department-jsx" */),
  "component---src-templates-downloads-downloads-jsx": () => import("./../../../src/templates/Downloads/Downloads.jsx" /* webpackChunkName: "component---src-templates-downloads-downloads-jsx" */),
  "component---src-templates-home-home-jsx": () => import("./../../../src/templates/Home/Home.jsx" /* webpackChunkName: "component---src-templates-home-home-jsx" */),
  "component---src-templates-news-news-jsx": () => import("./../../../src/templates/News/News.jsx" /* webpackChunkName: "component---src-templates-news-news-jsx" */),
  "component---src-templates-page-page-jsx": () => import("./../../../src/templates/Page/Page.jsx" /* webpackChunkName: "component---src-templates-page-page-jsx" */),
  "component---src-templates-product-variant-jsx": () => import("./../../../src/templates/Product/Variant.jsx" /* webpackChunkName: "component---src-templates-product-variant-jsx" */),
  "component---src-templates-shop-cart-jsx": () => import("./../../../src/templates/Shop/Cart.jsx" /* webpackChunkName: "component---src-templates-shop-cart-jsx" */)
}

