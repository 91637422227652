import React from 'react';
import BEMHelper from 'react-bem-helper';
import { Trans, useTranslation } from 'react-i18next';

import './details.scss';

const bem = BEMHelper({
  name: 'cookieconsent__details',
});

export const Details = () => {
  const { t } = useTranslation();

  return (
    <div {...bem()}>
      <p>
        <Trans
          i18nKey="privacy-cookie-settings-description"
          values={{ link: t('Privacy Notice') }}
        >
          Our website uses cookies. Please read our
          <a href="/data-protection" target="_blank">
            Privacy Notice
          </a>
          for mor details.
        </Trans>
      </p>
      <p {...bem('details')}>
        <Trans i18nKey="privacy-cookie-settings-advice">
          Please select the cookies we can set on your device:
        </Trans>
      </p>
    </div>
  );
};
