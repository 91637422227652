import i18n from 'i18next';
import React from 'react';
import { initReactI18next } from 'react-i18next';

import i18nOptions from './src/helpers/i18n';
import Layout from './src/templates/Layout';
import { childrenType } from './src/types';
import { AuthProvider } from './src/hooks/useAuth';
import { LocalStorageProvider } from './src/hooks/useLocalStorage';
import { ModalProvider } from './src/hooks/useModal';
import { NotificationProvider } from './src/hooks/useNotification';
import { VideoProvider } from './src/hooks/useVideo';

import './src/styles/global.scss';

i18n.use(initReactI18next).init(i18nOptions);

export const wrapPageElement = ({ element }) => (
  <LocalStorageProvider>
    <NotificationProvider>
      <AuthProvider>
        <VideoProvider>
          <ModalProvider>
            <Layout>{element}</Layout>
          </ModalProvider>
        </VideoProvider>
      </AuthProvider>
    </NotificationProvider>
  </LocalStorageProvider>
);

wrapPageElement.propTypes = {
  element: childrenType.isRequired,
};
