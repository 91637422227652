const numeral = require('numeral');
const en = require('../translations/en.json');
require('numeral/locales');

module.exports = {
  resources: {
    en: {
      translation: en,
    },
  },
  lng: 'de',
  fallbackLng: 'en',
  nsSeparator: false,
  keySeparator: false,
  returnEmptyString: false,
  interpolation: {
    escapeValue: false,
    format: (value, format, lng) => {
      numeral.locale(lng);

      if (format === 'currency') return numeral(value).format('0,0[.]00 $');

      return value;
    },
  },
};
