import {
  arrayOf, bool, func, shape, string,
} from 'prop-types';
import BEMHelper from 'react-bem-helper';
import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../common/button';
import { Details } from './sections/details';
import { Groups } from './sections/groups';
import './cookieconsent.scss';

const bem = BEMHelper({
  name: 'cookieconsent',
});

export const Cookieconsent = ({ groups, updateCallback, closeCallback }) => {
  const ref = useRef();
  const { t } = useTranslation();

  const onAllowAll = useCallback(() => {
    const all = groups.map(() => true);
    updateCallback(all);
    closeCallback();
  }, [groups, updateCallback, closeCallback]);

  const onRejectAll = useCallback(() => {
    const all = groups.map(() => false);
    updateCallback(all);
    closeCallback();
  }, [groups, updateCallback, closeCallback]);

  const onAllowSelection = useCallback(() => {
    const { current } = ref;
    const selection = groups.map(({ key }) => current.querySelector(`[name=${key}]`).checked);
    updateCallback(selection);
    closeCallback();
  }, [ref, groups, updateCallback, closeCallback]);

  return (
    <div ref={ref} {...bem()}>
      <h1 {...bem('headline')}>{t('Cookie Settings')}</h1>

      <Details />
      <Groups groups={groups} />

      <div {...bem('controls')}>
        <button
          {...bem('controls__selection')}
          type="button"
          onClick={onAllowSelection}
          variant="secondary"
          title={t('Click here to allow the selected cookies')}
        >
          {t('Allow selection')}
        </button>

        <Button
          {...bem('controls__none')}
          type="button"
          variant="secondary"
          onClick={onRejectAll}
          title={t('Click here to reject all cookies')}
        >
          {t('Reject all')}
        </Button>

        <Button
          {...bem('controls__all')}
          type="button"
          onClick={onAllowAll}
          title={t('Click here to allow all cookies')}
        >
          {t('Allow all')}
        </Button>
      </div>
    </div>
  );
};

Cookieconsent.propTypes = {
  groups: arrayOf(shape({
    key: string.isRequired,
    title: string.isRequired,
    description: string.isRequired,
    allowed: bool,
  })).isRequired,
  closeCallback: func.isRequired,
  updateCallback: func.isRequired,
};
