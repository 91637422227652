import { useStaticQuery, graphql, Link } from 'gatsby';
import React from 'react';
import BEMHelper from 'react-bem-helper';
import { useTranslation } from 'react-i18next';
import { ExternalLink } from 'react-external-link';
import { resolve } from '../../../helpers/urls';
import { usePrivacyModal } from '../../../hooks/usePrivacy';
import './footer.scss';

const bem = BEMHelper({
  name: 'footer',
});

const query = graphql`
  query {
    response: craftgql {
      contact: globalSet(handle: "contact") {
        ...on CraftGQL_contact_GlobalSet {
          company: contactCompany
          street: contactStreet
          housenumber: contactHouseNumber
          zipCode: contactZipCode
          city: contactCity
          country: contactCountry
          email: contactMail
          phone: contactPhone
          fax: contactFax
        }
      }
      entries(
        section: "pages",
        pageInNavigation: "footernav",
      ) {
        ...on CraftGQL_pages_pages_Entry {
          title
          slug: pageSlug
          externalUrl: pageExternalUrl
          departments: pageDepartments {
            slug
          }
        }
      }
    }
  }
`;

export const Footer = ({ ...props }) => {
  const { t } = useTranslation();
  const { showModal } = usePrivacyModal();
  const { response } = useStaticQuery(query);
  const { entries, contact } = response;

  const {
    company, street, housenumber, zipCode, city, country, phone, fax, email,
  } = contact;
  return (
    <footer {...bem()} role="contentinfo" {...props}>
      <div {...bem('wrapper', 'top')} itemScope itemType="http://schema.org/Organization">
        <address {...bem('address')} itemProp="address" itemScope itemType="http://schema.org/PostalAddress">
          <span itemProp="name">{company}</span>
          <span itemProp="streetAddress">
            {street}
            {' '}
            {housenumber}
          </span>
          <span>
            <span itemProp="postalCode">{zipCode}</span>
            {' '}
            <span itemProp="addressLocality">{city}</span>
          </span>
          <span itemProp="addressCountry">{country}</span>
        </address>
        <dl {...bem('desc-list')}>
          <div {...bem('desc-wrapper')}>
            <dt {...bem('term')}>{t('Phone')}</dt>
            <dd {...bem('details')}>
              <a
                {...bem('link')}
                title={t('Click here to call us')}
                href={`tel:${phone.replace(/\s/g, '')}`}
                itemProp="telephone"
              >
                {phone}
              </a>
            </dd>
            <dt {...bem('term')}>{t('Fax')}</dt>
            <dd {...bem('details')}>
              <a
                {...bem('link')}
                title={t('Click here to fax us')}
                href={`fax:${fax.replace(/\s/g, '')}`}
                itemProp="faxNumber"
              >
                {fax}
              </a>
            </dd>
            <dt {...bem('term')}>{t('E-mail')}</dt>
            <dd {...bem('details')}>
              <a
                {...bem('link')}
                title={t('Click here to email us')}
                href={`mailto:${email}`}
                itemProp="email"
              >
                {email}
              </a>
            </dd>
          </div>
          <div {...bem('desc-wrapper', 'social')}>
            <dt {...bem('term')}>{t('Social')}</dt>
            <dd {...bem('details', 'social')}>
              <ul>
                <li {...bem('social')}><a {...bem('link')} target="_blank" rel="noopener" title={t('Visit our Facebook page')} href="https://www.facebook.com/pages/category/Product-Service/MedSkin-Solutions-Dr-Suwelack-AG-104728887894235/">Facebook</a></li>
                <li {...bem('social')}><a {...bem('link')} target="_blank" rel="noopener" title={t('Visit our Instagram page')} href="https://www.instagram.com/medskinsolutions/">Instagram</a></li>
                <li {...bem('social')}><a {...bem('link')} target="_blank" rel="noopener" title={t('Visit our Twitter page')} href="https://twitter.com/MedSkin_MDS">Twitter</a></li>
                <li {...bem('social')}><a {...bem('link')} target="_blank" rel="noopener" title={t('Visit our LinkedIn page')} href="https://www.linkedin.com/company/12702679/">LinkedIn</a></li>
              </ul>
            </dd>
          </div>
        </dl>
      </div>
      <div {...bem('end-note')}>
        <div {...bem('wrapper')}>
          <small {...bem('copyright')}>
            Medskin Suwelack Copyright
            {' '}
            {new Date().getFullYear().toString()}
          </small>
          <ul {...bem('link-list')}>
            {entries.map(({
              slug, title, departments, externalUrl,
            }) => {
              const to = resolve('pages:detail', {
                pageSlug: slug,
                departmentSlug: departments[0]?.slug,
              });
              const url = externalUrl;

              return (
                <li key={slug} {...bem('link-item')}>
                  {url && <ExternalLink href={url} {...bem('link')}>{title}</ExternalLink>}
                  {!url && (
                  <Link {...bem('link')} to={to} title={t('Go to "{{ page }}"', { page: title })}>
                    {title}
                  </Link>
                  )}
                </li>
              );
            })}
            <li {...bem('link-item')}>
              <button
                type="button"
                {...bem('link', 'cookiesettings')}
                onClick={showModal}
              >
                {t('Cookie settings')}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};
