import {
  arrayOf, bool, shape, string,
} from 'prop-types';
import BEMHelper from 'react-bem-helper';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import './groups.scss';

const bem = BEMHelper({
  name: 'cookieconsent__groups',
});

export const Groups = ({ groups }) => {
  const { t } = useTranslation();
  const [showDetails, setShowDetails] = useState(false);

  // Add a default consent group for "necessary" functions:
  const consents = useMemo(() => [{
    key: 'necessary',
    title: t('Necessary'),
    description: t('We use necessary cookies.'),
    allowed: true,
    disabled: true,
  }].concat(groups), [t, groups]);

  const onToggleDetails = useCallback(() => {
    setShowDetails(!showDetails);
  }, [showDetails, setShowDetails]);

  return (
    <div>
      <dl {...bem()}>
        {consents.map(({
          key, title, description, allowed, disabled,
        }) => (
          <div key={key} {...bem('group')}>
            <dt {...bem('group__title')}>
              <label
                {...bem('group__label', { 'is-readonly': disabled })}
                htmlFor={`consent-key-${key}`}
              >
                <input
                  {...bem('group__checkbox')}
                  type="checkbox"
                  id={`consent-key-${key}`}
                  name={key}
                  defaultChecked={allowed || false}
                  disabled={disabled}
                />
                {title}
              </label>
            </dt>
            <dd
              id={`consent-description-${key}`}
              {...bem('group__description')}
              aria-hidden={!showDetails}
            >
              {description}
            </dd>
          </div>
        ))}
      </dl>

      <button
        type="button"
        {...bem('toggle')}
        aria-pressed={showDetails}
        aria-controls={groups.map(({ key }) => `consent-description-${key}`).join(' ')}
        onClick={onToggleDetails}
      >
        {showDetails ? t('Hide details') : t('Show details')}
      </button>
    </div>
  );
};

Groups.propTypes = {
  groups: arrayOf(shape({
    key: string.isRequired,
    title: string.isRequired,
    description: string.isRequired,
    allowed: bool,
  })).isRequired,
};
