import loadable from '@loadable/component';
import { canUseDOM } from 'exenv';
import { navigate } from 'gatsby';
import React, {
  createContext,
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { resolve } from '../helpers/urls';
import { childrenType } from '../types';
import {
  isEnabled,
  createClient,
  validateUser,
  login,
  logout,
  reset,
} from './useAuth/auth0';
import { useLocalStorage } from './useLocalStorage';
import { useModal } from './useModal';

const STORAGE_KEY = '_medskinAuthRedirect-v1';

const Login = loadable(() => import('../components/common/auth/login'));
const Request = loadable(() => import('../components/common/auth/request'));
const Reset = loadable(() => import('../components/common/auth/reset'));

const Context = createContext({ /* SSR Fallback */ });

export const AuthProvider = ({ children }) => {
  if (!isEnabled || !canUseDOM) {
    return (<>{children}</>);
  }

  const client = useMemo(createClient, []);

  const [error, setError] = useState(undefined);
  const [user, setUser] = useState(undefined);

  useEffect(() => {
    validateUser(client)
      .then(setUser)
      .catch(setError);
  }, []);

  const { Provider } = Context;
  return (<Provider value={{ client, error, user }}>{children}</Provider>);
};

AuthProvider.propTypes = {
  children: childrenType.isRequired,
};

export const useAuth = () => {
  const { t } = useTranslation();
  const [redirect, setRedirect, deleteRedirect] = useLocalStorage(STORAGE_KEY);
  const { client, error, user } = useContext(Context);
  const info = user?.info || null;

  const isLoading = useMemo(() => (user === undefined && !error), [error, user]);
  const isAuthenticated = useMemo(() => (!!user?.accessToken && !error), [error, user]);

  const loginFn = useCallback((credentials) => {
    setRedirect(window.location.pathname);
    return login(client, credentials);
  }, []);
  const logoutFn = useCallback(() => logout(client), []);
  const resetFn = useCallback((credentials) => reset(client, credentials), []);
  const loginCompleteFn = useCallback(
    () => {
      deleteRedirect();

      if (!redirect || redirect.match(resolve('auth:root'))) {
        navigate(resolve('home'));
        return;
      }
      navigate(redirect);
    },
    [redirect, deleteRedirect],
  );

  const {
    showModal: openRequestAccess,
    hideModal: closeRequestAccess,
  } = useModal(Request, {
    title: t('Request access'),
    componentProps: useCallback(() => ({
      id: 'auth-request-access',
      onRequest: closeRequestAccess,
    }), []),
  });

  const {
    showModal: openReset,
    hideModal: closeReset,
  } = useModal(Reset, {
    title: t('Reset password'),
    componentProps: useCallback(() => ({
      onReset: (...args) => {
        resetFn(...args);
        closeReset();
      },
    }), [resetFn]),
  });

  const {
    showModal: openLogin,
  } = useModal(Login, {
    title: t('Login'),
    componentProps: {
      onLogin: loginFn,
      onResetPassword: openReset,
      onRequestAccess: openRequestAccess,
    },
  });

  return {
    user: info,

    isLoading,
    isAuthenticated,

    error,
    login: loginFn,
    logout: logoutFn,
    reset: resetFn,

    loginComplete: loginCompleteFn,

    openRequestAccess,
    openReset,
    openLogin,
  };
};
