import { canUseDOM } from 'exenv';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { string } from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import BEMHelper from 'react-bem-helper';
import { useTranslation } from 'react-i18next';
import { ExternalLink } from 'react-external-link';
import { ReactComponent as Logo } from './logo.svg';
import { getLinkProps } from '../../content/teaser/utils';

import { useAuth } from '../../../hooks/useAuth';
import { useShop } from '../../../hooks/useShop';
import { resolve } from '../../../helpers/urls';
import { BehindLogin, WithoutLogin } from '../../common/auth/states';
import './navigation.scss';

const KEY_ESC = 27;

const bem = BEMHelper({
  name: 'navigation',
});

const query = graphql`
  query {
    response: craftgql {
      contact: globalSet(
        handle:"contact"
      ) {
        ...on CraftGQL_contact_GlobalSet {
          pages: contactPage {
            ... on CraftGQL_pages_pages_Entry {
              title
              slug: pageSlug
              departments: pageDepartments {
                ...on CraftGQL_departments_department_Entry {
                  slug
                }
              }
            }
          }
        }
      }

      departments: entries(
        section: "departments",
        orderBy: "navigationOrder ASC"
      ) {
        title
        slug
        ...on CraftGQL_departments_department_Entry {
          id
          departmentDropdownItems {
            id
            ... on CraftGQL_columnTeaser_teaser_Entry {
              title
              slug
              contents: pageContents {
                ... on CraftGQL_pageContents_pageTeaserM_BlockType {
                  id
                  typename: __typename
                  reference {
                    url
                    text
                    target
                    title
                    element {
                      ... on CraftGQL_pages_pages_Entry {
                        __typename
                        slug: pageSlug
                        departments: pageDepartments {
                          ... on CraftGQL_departments_department_Entry {
                            slug
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      mainnav: entries(
        section: "pages",
        pageInNavigation: "mainnav",
        orderBy: "navigationOrder ASC"
      ) {
        ...on CraftGQL_pages_pages_Entry {
          title
          slug: pageSlug
          externalUrl: pageExternalUrl
          departments: pageDepartments {
            slug
          }
        }
      }

      subnav: entries(
        section: "pages",
        pageInNavigation: "subnav",
        orderBy: "navigationOrder ASC"
      ) {
        ...on CraftGQL_pages_pages_Entry {
          title
          slug: pageSlug
          externalUrl: pageExternalUrl
          departments: pageDepartments {
            slug
          }
        }
      }
    }
  }
`;

export const Navigation = ({ contentsId }) => {
  const { t } = useTranslation();
  const { logout, openLogin } = useAuth();
  const { isEnabled: isShopEnabled } = useShop();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { response } = useStaticQuery(query);
  const {
    departments, mainnav, subnav, contact,
  } = response;

  const onKeyDown = useCallback(({ keyCode }) => {
    switch (keyCode) {
      case KEY_ESC:
        setIsDialogOpen(false);
        break;

      default:
        break;
    }
  });

  const onClickLink = useCallback(
    () => setIsDialogOpen(false),
    [setIsDialogOpen],
  );

  const onClickToggle = useCallback(
    () => setIsDialogOpen(!isDialogOpen),
    [setIsDialogOpen, isDialogOpen],
  );

  const onClickLogin = useCallback(() => {
    setIsDialogOpen(false);
    openLogin();
  }, [setIsDialogOpen, openLogin]);

  const onClickLogout = useCallback(() => {
    setIsDialogOpen(false);
    logout();
  }, [setIsDialogOpen, logout]);

  useEffect(() => {
    if (!canUseDOM) {
      return;
    }

    const contents = document.getElementById(contentsId);
    contents.inert = isDialogOpen;
  }, [contentsId, isDialogOpen]);

  return (
    <>
      <nav // eslint-disable-line jsx-a11y/no-noninteractive-element-interactions
        {...bem(null, { open: isDialogOpen })}
        role="navigation"
        aria-label={t('Navigation')}
        onKeyDown={onKeyDown}
      >
        <div {...bem('wrapper')}>
          <Link
            to={resolve('home')}
            title={t('Go to homepage')}
            rel="index"
            {...bem('home')}
          >
            <Logo {...bem('logo')} aria-hidden="true" role="presentation" />
            <span {...bem('sr')}>MedSkin Solutions Dr. Suwelack</span>
          </Link>
          <ul aria-label={t('Main navigation')} {...bem('main-list')}>
            {/* List all departments */}
            {departments.map((department) => {
              const link = resolve('departments:detail', {
                departmentSlug: department.slug,
              });

              const hasDropdown = department.departmentDropdownItems.length > 0;

              return (
                <li key={link} {...bem('main-list-item', null, 'group relative')}>
                  <div className="flex items-center justify-between">
                    <Link
                      onClick={onClickLink}
                      to={link}
                      title={t('Go to "{{ page}}"', { page: department.title })}
                      activeClassName={bem('link', 'active').className}
                      {...bem('link')}
                    >
                      {department.title}
                    </Link>
                    {hasDropdown && (
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="#000000"
                          className="h-6 w-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                          />
                        </svg>
                    </span>
                    )}
                  </div>
                  {hasDropdown && (
                    <div {...bem('main-item-dropdown')}>
                      <ul>
                        {department.departmentDropdownItems.map((item, index) => {
                          return(
                            <li key={department.slug + '-' + index}><Link {...getLinkProps(item.contents[0].reference)}>{item.title}</Link></li>
                          )
                        })}
                      </ul>
                    </div>
                  )}
                </li>
              );
            })}

            {/* List all pages that are marked to be inside the main navigation */}
            {mainnav.map((page) => {
              const [department] = page.departments;
              const link = resolve('pages:detail', {
                pageSlug: page.slug,
                departmentSlug: department?.slug,
              });
              const url = page.externalUrl;

              return (
                <li key={page.slug} {...bem('main-list-item')}>
                  {url && <ExternalLink href={url} {...bem('link')}>{page.title}</ExternalLink>}
                  {!url && (
                  <Link
                    onClick={onClickLink}
                    to={link}
                    title={t('Go to "{{ page}}"', { page: page.title })}
                    activeClassName={bem('link', 'active').className}
                    {...bem('link')}
                  >
                    {page.title}
                  </Link>
                  )}
                </li>
              );
            })}
          </ul>
          <div {...bem('rightside')}>
            {contact.pages.length > 0 && contact.pages.map(({ slug, title }) => (
              <Link
                key={slug}
                to={`/${slug}`}
                title={t('Go to "{{ page }}"', { page: title })}
                activeClassName={bem('contact', 'active').className}
                {...bem('contact')}
              >
                {title}

              </Link>
            ))}
            <button
              type="button"
              title={isDialogOpen ? t('Click here to close the navigation') : t('Click here to open the navigation')}
              aria-expanded={isDialogOpen}
              aria-controls="sub-list"
              aria-pressed={isDialogOpen}
              onClick={onClickToggle}
              {...bem('toggle')}
            >
              <span {...bem('sr')}>Menu</span>
              <span {...bem('hamburger-box')}>
                <span {...bem('hamburger-inner')} />
              </span>
            </button>
          </div>
          <div {...bem('sub-wrapper')}>
            <ul
              aria-label={t('Sub navigation')}
              aria-hidden={!isDialogOpen}
              id="sub-list"
              {...bem('sub-list')}
            >
              {/* List all pages that are related to a department */}
              {departments.map((department) => {
                const entries = subnav
                  .filter((page) => {
                    const [dep] = page.departments;
                    return dep?.slug === department.slug;
                  });

                const departmentLink = resolve('departments:detail', {
                  departmentSlug: department.slug,
                });

                return (
                  <li key={departmentLink} {...bem('sub-list-item')}>
                    <Link
                      tabIndex={isDialogOpen ? 0 : -1}
                      onClick={onClickLink}
                      to={departmentLink}
                      title={t('Go to "{{ page }}"', { page: department.title })}
                      activeClassName={bem('link', 'active').className}
                      {...bem('link')}
                    >
                      {department.title}
                    </Link>

                    {(entries.length > 0) && (
                    <ul {...bem('entry-list')}>
                      {entries.map((page) => {
                        const pageLink = resolve('pages:detail', {
                          pageSlug: page.slug,
                          departmentSlug: department.slug,
                        });

                        return (
                          <li key={pageLink} {...bem('entry-list-item')}>
                            <Link
                              tabIndex={isDialogOpen ? 0 : -1}
                              onClick={onClickLink}
                              to={pageLink}
                              title={t('Go to "{{ page }}"', { page: page.title })}
                              activeClassName={bem('link', 'active').className}
                              {...bem('link')}
                            >
                              {page.title}
                            </Link>
                          </li>
                        );
                      })}
                      { /* MDS-9 */ }
                      {/* Links and actions that are accessible without a login */}
                      { department.title === 'SkinCare' && (
                        <>
                          <WithoutLogin>
                            <li {...bem('entry-list-item')}>
                              <button {...bem('link', 'account-sign-in')} type="button" onClick={onClickLogin}>
                                {t('Sign in')}
                              </button>
                            </li>
                          </WithoutLogin>
                          {/* Links and actions that are behind a login */}
                          <BehindLogin>
                            {isShopEnabled && (
                            <li {...bem('entry-list-item')}>
                              <Link
                                {...bem('link', 'account-cart')}
                                to={resolve('shop:cart')}
                                tabIndex={isDialogOpen ? 0 : -1}
                                activeClassName={bem('link', 'active').className}
                                onClick={onClickLink}
                              >
                                {t('Your Cart')}
                              </Link>
                            </li>
                            )}
                            <li {...bem('entry-list-item')}>
                              <Link
                                {...bem('link', 'account-downloads')}
                                to={resolve('downloads:list')}
                                tabIndex={isDialogOpen ? 0 : -1}
                                activeClassName={bem('link', 'active').className}
                                onClick={onClickLink}
                              >
                                {t('Downloads')}
                              </Link>
                            </li>
                            <li {...bem('entry-list-item')}>
                              <button
                                {...bem('link', 'account-sign-out')}
                                type="button"
                                onClick={onClickLogout}
                              >
                                {t('Sign out')}
                              </button>
                            </li>
                          </BehindLogin>
                        </>
                      ) }
                    </ul>
                    )}
                  </li>
                );
              })}

              {/* List all pages not related to a department */}
              {subnav
                .filter((page) => page.departments.length === 0)
                .map((page) => {
                  const link = resolve('pages:detail', {
                    pageSlug: page.slug,
                  });
                  const url = page.externalUrl;

                  return (
                    <li key={page.slug} {...bem('sub-list-item')}>
                      {url && <ExternalLink href={url} {...bem('link')}>{page.title}</ExternalLink>}
                      {!url && (
                      <Link
                        tabIndex={isDialogOpen ? 0 : -1}
                        onClick={onClickLink}
                        to={link}
                        activeClassName={bem('link', 'active').className}
                        {...bem('link')}
                      >
                        {page.title}
                      </Link>
                      )}
                    </li>
                  );
                })}
              { /* Make up one dummy li to accomodate the sign in button */ }
              <li>&nbsp;</li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

Navigation.propTypes = {
  contentsId: string.isRequired,
};
