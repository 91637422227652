import { useLocation } from '@reach/router';
import { canUseDOM } from 'exenv';
import { useEffect, useState } from 'react';

import { usePrivacyConsent } from '../../../hooks/usePrivacy';

const trackPageView = (pagePath, pageTitle) => {
  window.dataLayer.push({ event: 'Pageview', pagePath, pageTitle });
};

export const useGoogleTagManager = (id) => {
  const allowed = usePrivacyConsent('tracking');
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!allowed || loaded || !canUseDOM || !id) {
      return;
    }

    // Readable and eslint valid version of GTM-Script:
    // (simplified, no dataLayer name change)
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'gtm.start': new Date().getTime(),
      'event': 'gtm.js',
    });

    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtm.js?id=${id}`;
    document.head.appendChild(script);

    // Mark as loaded and initialized:
    setLoaded(true);
  }, [id, allowed, loaded, setLoaded]);

  const { pathname } = useLocation();
  useEffect(() => {
    if (!allowed) {
      // Do not track the initial view or since the user accepted the tracking
      return;
    }

    // wrap inside a timeout to ensure the title has properly been changed
    setTimeout(() => trackPageView(pathname, document.title), 50);

    // We only want to react on pathname changes.
  }, [pathname]);
};
