const { define } = require('routz');

const ROUTES = {
  'home': '/',
  'auth:root': '/auth',
  'auth:callback': '/auth/callback/', // ending slash is required for auth0 and safari authentication
  'auth:logout': '/auth/logout/', // ending slash is required for auth0 and safari authentication
  'departments:detail': '/[departmentSlug]',
  'downloads:list': '/downloads',
  'news:detail': '/[newsSlug?]/[pageSlug]',
  'products:list': '/[departmentSlug]/products',
  'products:item': '/[departmentSlug]/products/[productSlug]',
  'products:detail': '/[departmentSlug]/products/[productSlug]/[variantSlug]',
  'pages:detail': '/[departmentSlug?]/[pageSlug]',
  'shop:root': '/shop',
  'shop:cart': '/shop/cart',
};

const { resolve } = define(ROUTES);
module.exports.resolve = resolve;

/**
 * Parses query parameters from url and returns them as key/value object.
 */
module.exports.query = (url) => {
  const [, query] = url.split('?');
  if (!query) {
    return {};
  }

  const params = {};
  query.split('&').forEach((part) => {
    const [key, value] = part.split('=');
    params[key] = decodeURIComponent(value);
  });
  return params;
};
