import { useEffect } from 'react';

import { usePrivacy, usePrivacyModal } from '../../../hooks/usePrivacy';

export const Privacycontrol = () => {
  const { groups } = usePrivacy();
  const { showModal } = usePrivacyModal();
  const needsConsent = groups.some(({ allowed }) => allowed === undefined);

  useEffect(() => {
    if (!needsConsent) {
      return;
    }

    showModal();
  }, [showModal, needsConsent]);

  return null;
};
