import React, { useEffect } from 'react';
import BEMHelper from 'react-bem-helper';
import {
  string, arrayOf, shape, oneOf, func,
} from 'prop-types';

import { childrenType } from '../../../types';
import './notifications.scss';

const AUTO_CLOSE_TIME = 7000;

const bem = new BEMHelper({
  name: 'notifications',
});

const Notification = ({
  id, variant, onRemove, children,
}) => {
  useEffect(() => {
    const timer = setTimeout(() => onRemove(id), AUTO_CLOSE_TIME);

    return () => {
      clearTimeout(timer);
    };
  }, [id, onRemove]);

  switch (variant) {
    case 'error':
      return (
        <div role="alert" {...bem('element', 'error')}>
          <span {...bem('text')}>{children}</span>
        </div>
      );
    default:
      return (
        <div role="log" {...bem('element', variant)}>
          <span {...bem('text')}>{children}</span>
        </div>
      );
  }
};

Notification.propTypes = {
  id: string.isRequired,
  variant: oneOf(['success', 'error']).isRequired,
  onRemove: func.isRequired,
  children: childrenType.isRequired,
};

export const Notifications = ({ notifications, onRemove }) => (
  <ul {...bem()} aria-live="polite" aria-hidden={notifications.length === 0}>
    {notifications.map(({ id, variant, content }) => (
      <li key={id}>
        <Notification id={id} variant={variant} onRemove={onRemove}>
          {content}
        </Notification>
      </li>
    ))}
  </ul>
);

Notifications.propTypes = {
  notifications: arrayOf(shape({
    id: string.isRequired,
    variant: oneOf(['success', 'error']).isRequired,
    content: childrenType.isRequired,
  })).isRequired,
  onRemove: func.isRequired,
};
