import React, { useState, createContext } from 'react';
import { childrenType } from '../types';

const VideoContext = createContext();

const VideoProvider = ({ children }) => {
  const [videos, setVideos] = useState([]);

  return (
    <VideoContext.Provider
      value={{
        videos,
        setVideos,
      }}
    >
      {children}
    </VideoContext.Provider>
  );
};

export default VideoContext;

export { VideoProvider };

VideoProvider.propTypes = {
  children: childrenType.isRequired,
};
