import { string } from 'prop-types';
import React from 'react';
import BEMHelper from 'react-bem-helper';

import { childrenType } from '../../../types';
import './contents.scss';

const bem = BEMHelper({
  name: 'contents',
});

export const Contents = ({ id, children }) => (
  <div {...bem()} id={id}>
    {children}
  </div>
);

Contents.propTypes = {
  id: string.isRequired,
  children: childrenType.isRequired,
};
